.headerSection {
  width: 100%;
  /* height: 100%; */
  top: 0;
  position: sticky;
  z-index: 100;
  background-color: #fff;
}

.loginName {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 600;
}

.logo {
  width: 100%;
  height: auto;
}

.activeTab {
  color: #f3ac01 !important;
}

.logoDiv {
  width: 15%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.headerTwoMain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  width: 35%;
  justify-content: space-between;
  /* padding-top: 1.4%; */
}

.navText {
  color: #1c1c1c;
  font-family: "Inter", sans-serif;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.navItemDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.verticalLine {
  width: 1px;
  height: 2vh;
  background: #a1a0b2;
  /* margin-left: 1.6vw;
    margin-right: 1.7vw; */
}

/* .contactUsBtn:hover {
    /* animation: ContactBtnHover 0.5s; */
/* transform: translate3d(0, -10px, 0); */
/* animation-fill-mode: forwards; */
/* } */

.contactUsBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: linear-gradient(90deg, #fc4a1a 0%, #f7b733 100%);
  transition: transform 0.5s;
  width: clamp(100px, 10vw, 175px);
  padding: 9.6% 0;
}

.contactBtnText {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: clamp(0.9rem, 1.5vw, 1.25rem);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}

.headerTwobtns {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.headerTwobtnsStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.registerBtn {
  font-family: "Inter", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  background: linear-gradient(90deg, #fc4a1a 0%, #f7b733 100%);
  height: 35px;
  border-radius: 5px;
  padding: 8px 16px;
}

button {
  background-color: transparent;
  font-size: xx-large;
  border: none;
  cursor: pointer;
}

.navText:hover {
  color: #f3ac01;
}

.preHeaderMain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  margin-bottom: 20px;
}

.preHeader {
  background-color: black;
  max-height: 60px;
  height: 60px;
  /* padding-left: 5.2%;
    padding-right: 6.5%; */
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  /* margin-bottom: 1.17%; */
  width: 90%;
}

.preHeaderContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .preHeaderContentRight {
    width: clamp(100px, 10vw, 175px);
} */

.preHeaderContentText {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: clamp(0.9rem, 1.5vw, 1.25rem);
  font-style: normal;
  font-weight: 500;
  margin: 0;
  margin-left: 16px;
}

.contentSeperator {
  background: #fff;
  width: 1px;
  height: 16px;
  margin-right: 24px;
  margin-left: 24px;
}

.contentLogo {
  height: auto;
  object-fit: contain;
  max-height: 20px;
  max-width: 20px;
  width: clamp(10px, 2vh, 20px);
}

.socialContainer {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
}

.headerAnchor {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
}

.socialLogo {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 20px;
  max-width: 20px;
}

.btnDiv {
  /* width: 15%; */
  display: flex;
  justify-content: center;
}

@keyframes ContactBtnHover {
  100% {
    transform: translate3d(0, -10px, 0);
  }
}

/* Hamburger design and responsive designs */
.hamburgerHeader {
  display: none;
}
.hamburgerContainer {
  padding: 7px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.hamburgerSlider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  animation: slideOpen 0s 0.2s forwards;
  touch-action: auto;
  pointer-events: auto;
}
.sliderOpen {
  width: 70vw;
  z-index: 40;
  /* transform: translateX(0); */
}
.sliderClosed {
  transform: translateX(100%);
}
.section {
  padding: 40px;
  height: 100vh;
}
.sectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  gap: 55px;
}
.closeBtn {
  align-self: end;
}
.closeBtn img {
  height: 30px;
  width: 30px;
}
.headerLogo {
  height: 100px;
}
.headerLogo img {
  height: 100%;
  object-fit: cover;
}
.menuList {
  margin-top: 92px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: start;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  height: 100%;
  color: #1c1c1c;
  width: 100%;
  overflow: auto;
  padding: 0 15px;
}
.menuList a {
  text-decoration: none;
  color: #1c1c1c;
}
.hide {
  display: none;
}
.greyBgMain {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}
.bigContentSeperator {
  background: #fff;
  width: 2px;
  height: 40px;
  margin-right: 40px;
  margin-left: 40px;
}
.countryName {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  margin-right: 24px;
  line-height: 24px;
}
.preHeaderContent{
    display: flex;
    flex-direction: row;
}
.preHeaderContent div:not(.bigContentSeperator,.contentSeperator){
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width: 1800px) {
    .preHeader{
        max-height: 70px;
        height: 70px;
    }
    .preHeaderContent{
        flex-direction: column;
        height: 100%;
        align-items: baseline;
    }
    .preHeaderContent div:not(.bigContentSeperator,.contentSeperator){
        height: 100%;
        align-items: center;
    }
    .bigContentSeperator{
        display: none;
    }
    .contentSeperator{
        /* height: 5px; */
    }
}
@media only screen and (max-width: 1025px) {
  .headerTwoMain {
    display: none;
  }
  .hamburgerHeader {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    height: 48px;
  }
  .preHeaderMain {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 768px) {
  .headerTwoMain {
    display: none;
  }
  .hamburgerHeader {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    height: 35px;
  }
  .hamburgerHeader .logoDiv {
    width: auto;
    display: flex;
    height: 27px;
  }
  .hamburgerHeader .logoDiv img {
    height: 27px;
    width: 78px;
  }
  .sliderOpen {
    width: 100vw;
  }

  .sectionContainer {
    justify-content: flex-end;
    gap: 50px;
  }
  .menuList {
    padding: 0 10px;
    font-size: 24px;
    line-height: 30px;
    gap: 60px;
  }
  .section {
    padding: 24px;
  }
  .headerLogo {
    height: 80px;
  }
  .preHeaderContent {
    display: none;
  }
  .preHeader {
    justify-content: end;
    -webkit-justify-content: flex-end;
    height: 41px;
  }
  .hamburgerContainer img {
    height: 15px;
  }
}
