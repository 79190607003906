.sectionContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*--------------Section 1 CSS ------------------*/

.section1{
    background-image: url('../images/CourseBG.png');
    width: 100%;
    padding: 10% 0px;
    display: flex;
    flex-direction: column;
    background-repeat: round;
}
.section1 .titleText{
    color: #FFFFFF;
    font-family: 'Bebas Neue',sans-serif;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    padding: 20px;
    padding-left: 100px;
}

.secondContent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF7EF;
    height: 100px;
    width: calc(100% - 154px);
    padding: 0 77px;
}

.showText{
    font-family: 'Inter',sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.selectFlex{
    display: flex;
    align-items: center;
    gap: 12px;
}
.imgFlex{
    display: flex;
    align-items: center;
    gap: 32px;
}

.selectStyle{
    height: 50px;
    border-radius: 10px;
    border: 2px;
    width: 414px;
    padding: 8px 16px;
}



.optionFont{
    font-family: 'Inter',sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.optionFont:hover{
    background-color: #FFEED9;
}

.cardImg{
    width: 30px;
    height: 30px;
}

.cardHolder{
    /* display: flex; */
    gap: 20px;
    /* flex-wrap: wrap; */
    padding: 77px;
    /* justify-content: center; */

    display: grid;
    
    grid-template-columns: repeat(4,1fr);
   }

/* List view css ------------------*/
.innerNum{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainDiv{
    padding: 50px;
    width: 100%;
}
.mainDivFlex{
    display: flex;
    gap: 40px;
    width: 100%;
}

.subTwoDiv{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.cardImgList{
    width: 415px;
    height: 250px;
    border-radius: 10px;
    
}

.courseTitle{
    color: #F3AC01;
    background-color: #FFF7EF;
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    border: 1px solid;
    border-radius: 50px  ;
    padding: 4px 16px ;
    width: fit-content;
}

.courseDetail{
    font-family: "Inter",sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
}

.cardFooter{
    display: flex;
    gap: 10px;
    align-items: center;

}

.clockIcon{
    width: 18px;
    height: 18px;
}

.rightArrowSet{
    width: 25px;
    height: 12px;
    margin-right: 12px;
}

.timeDetail{
    color:  #676767;
    font-family: "Inter",sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
}

.footerVL{
    background-color: #BBBBBB;
    width: 2px;
    height: 20px;
}

.HLine{
    width: 100%;
    border: 1px;
    background: #DADADA;
    border: 1px solid #DADADA;
    margin-top: 45px;
    margin-bottom: 45px;

}

.lerarnMoreFlex{
    display: flex;
    align-items: center;
    color: #F3AC01;
    gap: 8px;
}
.learnMoreText{
    font-family: "Inter",sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}
.MainComponent{
    display: flex;
    justify-content: space-between;
    width: 96%;
}
/* List view css end --------------*/

.searchFlex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
}

.searchStyle{
    border: 2px solid #F3AC01;
    background-color: #FFF7EF;
    width: 415px;
    height: 50px;
    top: 613px;
    left: 1405px;
    border-radius: 10px;
    border: 2px;
    border: 2px solid #F3AC01;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    background-image: url('../images/Search.png');
    background-position: calc(100% - 13px);
    padding: 0px 23px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchStyle:focus{
    background:#FFFFFF;
    border: 2px solid #F3AC01;
}

.pagemain{
    display: flex;
    gap: 20px;
    list-style: none;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 16px;
}

.pagemain li{
    background-color:  #FFFFFF;
    border: 1px solid #F1F1F1 !important;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 32px;
    border: 1px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.pagemain li:hover{
    background: #FFFFFF;
    background-color: #F3AC01;
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 600;    
}

.pagemain .selected li{
    background: #FFFFFF !important;
    background-color: #F3AC01 !important;
    font-family: 'Inter',sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;  
}

.activeclass{
    background: #FFFFFF !important;
    background-color: #F3AC01 !important;
    font-family: 'Inter',sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;  
}


@media only screen and (max-width: 1600px){
    .section1 .titleText{
        font-size: 64px;
    }
    .selectStyle,.selectStyle{
        width: 350px;
    }
    .cardHolder{
        grid-template-columns: repeat(3,1fr);
    }
}


@media only screen and (max-width: 1200px){
    .secondContent{
        width: calc(100% - 48px);
    padding: 0 24px;
    }
   .cardHolder{
    grid-template-columns: repeat(2,1fr);
    padding: 77px 24px;
}
    .searchStyle{
        width: unset;
    }
    .MainComponent{
        width: 100%;
    }
    .MainComponent .mainDiv{
        padding: 40px 24px;
    }
}
@media only screen and (max-width: 991px){
    .secondContent{
        flex-direction: column;
        height: auto;
        width: calc(100% - 48px);
        padding: 16px 24px;
        align-items: start;
        gap: 24px;
    }
    .searchFlex,.selectStyle{
        width: 100%;
    }
    .selectFlex,.searchDiv{
        width: 50%;
    }
    .searchStyle{
        width: -webkit-fill-available;
    }
}
@media only screen and (max-width: 768px){
    .cardHolder{
        padding: 40px 16px;
        gap: 10px;
        width: calc(100% - 32px);
    }
    .section1 .titleText{
        font-size: 36px;
    }
    .mainDivFlex {
        flex-direction: column;
    }
    .mainDiv{
        padding: 40px 16px;
    }
    .cardImgList{
        width: 100%;
        height: auto;
    }
    .courseTitle{
        margin-left: 0;
    }
    .pagemain li{
        height: 25px;
        width: 25px;
    }
  
}

@media only screen and (max-width: 576px){
    .cardHolder{
        grid-template-columns: repeat(1,1fr);
        padding: 40px 16px;
        gap: 32px;
        width: calc(100% - 32px);
    }
    .searchFlex{
        flex-direction: column;
    }
    .searchFlex,.selectStyle{
        width: 100%;
    }
    .selectFlex,.searchDiv{
        width: 100%;
    }
    .searchStyle{
        width: -webkit-fill-available;
    }
}

.disabled {
    pointer-events: none;
    /* Prevent clicking */
    opacity: 0.5;
    /* Make it look disabled */
    cursor: not-allowed;
    /* Change the cursor to show it’s disabled */
}