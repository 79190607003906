@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinnerContainer {
    z-index: 1000;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(0.5);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
}

.spinnerComponentContainer{
    z-index: 99;
    width: 100%;
    height: 100%;
    /* backdrop-filter: brightness(0.5); */
    display: flex;
    margin: 20px 0;
    position: static;
    justify-content: center;
    align-items: center;
}

.spinnerComponentContainer .loadingSpinner{
    position: unset;
    margin: 20px 0;
}

.loadingSpinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #F3AC01;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
}

