.table{
    background-color: #FFFFFF;
    width: 100%!important;
    table-layout: auto;
}
.table thead{
    background-color:  #F5F5F5;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
}

.table tbody tr:nth-child(even) {
    background-color: #F3F7FF;
}

.table .dt-layout-row{
    display: flex;
}

.searchInput{
    border-radius: 50px;
    padding: 0px 24px;
    border-color: transparent;
    background-color: #F5F5F5;
    width: 400px;
    height: 50px;
    padding-right: 55px;
}


.tablePage{
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}
.firstFilterContainer,.secondFilterContainer{
    display: flex;
    justify-content: space-between;
    padding: 24px;
    align-items: center;
}
.firstFilterContainer{
    padding-bottom: 8px;
}
.entriesFilter,.dropdownFilter,.dateFilter{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #959595;
    gap: 8px;
}
.entriesFilter select, .dropdownFilter select{
    width: 190px;
    padding: 16px;
    border-color:#E0E0E0 ;
    border-radius: 5px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
    background-position: 100%;
    background-repeat: no-repeat;
}
.dateFilter .datePicker{
    height: 49px;
    color: #959595;
    width: 300px;
    border-color: #E0E0E0;
    border-radius: 5px;
}

.dropdownFilter select{
    width: 300px;
    padding-right: 40px;
}
.firstFilterContainer select{
    color: #959595;
}
.hide{
    display: none;
}
.exportButton{
    font-size: 18px;
    color: #4B6CB7;
    padding: 12px 24px;
    border: 1px solid #4B6CB7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
}
.exportButton:hover,.exportButton:hover path{
    color: #FFFFFF;
    background-color: #4B6CB7;
    stroke: #FFFFFF;
}
.searchDiv{
    position: relative;
}
.searchDiv img{
    position: absolute;
    top: 11px;
    right: 24px;
}

.filterWrapper{
    display: flex;
    gap: 20px;
}
