.btnClose {
    color: #aaaaaa;
    font-size: 20px;
    text-decoration: none;
    padding:10px;
    position: absolute;
    right: 7px;
    top: 0;
  }
  .btnClose:hover {
    color: #919191;
  }
  .modale:before {
    content: "";
    display: none;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  .opened:before {
    display: block;
  }
  .opened .modalDialog {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .closed{
    display: none;
  }
  .modalDialog {
    background: #fefefe;
    border: #333333 solid 0px;
    border-radius: 5px;
    text-align:center;
    position: fixed;
   

    z-index: 11;
    width: 584px;
    box-shadow:0 5px 10px rgba(0,0,0,0.3);
    padding: 32px;
   
  }
  .modalBody input{
    width:200px;
    padding:8px;
    border:1px solid #ddd;
    color:#888;
    outline:0;
    font-size:14px;
    font-weight:bold
  }
  .modalHeader{
    margin-bottom: 32px;
  }
  .modalHeader span{
    color: #232323;
    font-size: 20px;
    line-height: 30px;
  }
  
  .modalHeader h2 {
    font-size: 20px;
  }

  .banModal{
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .banModal label{
    font-size: 18px;
    
  }
  .banModal textarea{
    width: 100%;
    border-color: #E0E0E0;
    border-radius: 5px;
    resize: none;
  }

  .modalFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 32px;
  }
  .modalFooter button{
    padding: 10px 32px;
    font-size: 18px;
    border: 1px solid #4B6CB7;
    border-radius: 5px;
    cursor: pointer;
  }
  .modalFooter button:nth-child(1){
    background-color: #4B6CB7;
    color: #FFFFFF;
  }