.sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 100px;
}

/*--------------Section 1 CSS ------------------*/

.section1 {
    background-image: url('../images/AboutUsBG.png');
    width: 100%;
    padding: 5% 0px;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    background-repeat: round;
}

.section1 .titleText {
    color: #FFFFFF;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    padding: 20px;
    width: 90%;
}

/*--------------Section 2 CSS ------------------*/


.section2 {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.missionDiv {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.detailDiv {
    display: flex;
    width: 60%;
}

.missionDiv .titleText {
    color: #F3AC01;
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    text-align: left;
}

.missionDiv .subText {
    font-family: "Inter", sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 90px;
    text-align: left;

}

.detailDiv .text {
    color: #676767;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;

}

/*--------------Section 3 CSS ------------------*/

.section3 {
    /* background-image: url('../images/Section2BG.png'); */
    background: linear-gradient(to right, #614385, #9198e5);
    width: 89.6%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5.8% 5.2% 4.0% 5.2%;
    background-repeat: repeat-x;

}

.bgContentDiv {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.animCountDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.animCountDiv>span {
    color: #FFF;
    text-align: center;
    font-family: ' Expletus Sans';
    font-size: clamp(1rem, 3vw, 3rem);
    font-style: normal;
    font-weight: 700;
    margin: 0;
    margin-bottom: 16px;

}

.bgText {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: clamp(1rem, 3vw, 2.5rem);
    text-align: center;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}


@media only screen and (max-width: 1600px){
    .section1 .titleText{
        font-size: 64px;
    }
    .subText{
        font-size: 48px;
    }
}
@media only screen and (max-width: 1200px){
    .missionDiv{
        gap: 16px;
    }
    .missionDiv .subText{
        font-size: 40px;
        line-height: 60px;
    }
    .section2{
        flex-direction: column;
        gap: 32px;
    }
    .missionDiv, .detailDiv{
       width: 100%;
    }
    .sectionContainer{
        gap: 60px;
    }
}
@media only screen and (max-width: 768px){
    .section1 .titleText{
        font-size: 36px;
    }
    .missionDiv .titleText{
        font-size: 16px;
        line-height: 20px;
    }
    .missionDiv .subText{
        font-size: 24px;
        line-height: 40px
    }
    .section2{
        gap: 24px;
    }
    .detailDiv .text{
        font-size: 16px;
        line-height: 30px;
    }
    .sectionContainer{
        gap: 40px;
    }
    .section3{
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }
    .bgContentDiv{
        width: 100%;
    }
}