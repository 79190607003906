/* Timeline.module.css */

.timeline {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  width: 67%;
  position: relative;
}

.step {
  position: relative;
  text-align: center;
  /* cursor: pointer; */

  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
  height: 16px;
  width: 16px;
  z-index: 2;
}

.step.active {
  background-color: #4b6cb7;
  color: white;
  border-color: #4b6cb7;
  background-image: url("../../images/checkedIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.step:not(:last-child) {
  margin-right: 10px;
}

/* Style for connecting lines */
.line {
  position: absolute;
  top: 50%;
  height: 2px;
  background-color: #ccc;
  z-index: -1;
}

/* Active line */

/* Adjusting line position */
.line {
  position: absolute;
  height: 2px;
  top: 9px;
  left: 0;
  width: 100%;
  z-index: 1;
  transition: all 5 ease-in-out;
}

.coloredLine {
  height: 100%;
  background-color: #4b6cb7;
  width: 0;
  position: absolute;
  transition: all 5 ease-in-out;
}

.coloredLine.empty {
  width: 0%;
}
.coloredLine.oneFourth {
  width: 33%;
}
.coloredLine.half {
  width: 66%;
}
.coloredLine.full {
  width: 100%;
}

.coloredLine.oneFourth .firststep,
.coloredLine.oneFourth .secondstep {
  background-color: #4b6cb7;
}
.step.active {
  background-color: #4b6cb7;
}
.current {
  background-color: #4b6cb7;
  border-color: #4b6cb7;
  position: relative;
}
.currentCircle {
  background-color: #ffffff;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
