.sidenav {
  height: calc(100vh - 60px);
  width: 294px;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  border-radius: 20px;
  background: linear-gradient(to bottom, #182848, #4b6cb7);
}

.sidenav a:hover {
  color: #f1f1f1;
}

.adminChild {
  flex: 1;
}

.logoDiv {
  padding: 32px 46px;
}
.navLinks {
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100% - 157px);
  justify-content: space-between;
}
.navLink > div:hover,
.activeNav > div {
  border-radius: 10px;
  background: linear-gradient(to right, #ffffff, transparent);
}
.upperLinks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.navOption {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 18px 24px;
}

.navLinks a {
  font-size: 20px;
  color: #ffffff;
  line-height: 25px;
  text-decoration: none;
}
.navOption img {
  height: 20px;
  width: 20px;
}
