.headerContainer{
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    /* position: sticky;
    top: 0; */
}
.pageTitle{
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
}
.profileDetails{
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 40px;
}
.belIcon{
    position: relative;
    cursor: pointer;
}
.belIcon span{
    position: absolute;
    top: -4px;
    right: -8px;
    height: 20px;
    width: 20px;
    background-color: #D02F44;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
}
.adminImage{
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 50%;
}
.adminImage img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.userDetails{
    display: flex;
    gap: 16px;
    align-items: center;
}
.nameDetails .name{
    color: #232323;
    font-size: 18px;
    line-height: 22px;
}
.nameDetails .role{
    font-size: 14px;
    line-height: 17px;
    color: #767676;
}
.statusColorBox{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: red;
}
.statusBox{
    display: flex;
}