
.footerDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
    position: relative;
    padding-top: 100px;
}
.imgDiv{
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.imageSubText{
    color: #FFFFFF;
    font-family: 'Inter',sans-serif;
    font-size: clamp(30px,40px,48px);
    font-weight: 600;
    /* line-height: 60px; */
    text-align: center;
    width: 60%;
}

.textAndInput{
    position: absolute;
    display: flex;
    gap: 20px;
    width: 71%;
}

.emailTextboxDiv{
    display: flex;
    position: relative;
    justify-content: right;
    align-items: center;
    width: 40%;
}

.inputEmail{
    width: 500px;
    height: 65px;
    border-radius: 10px;
    border: none;
    padding-left: 20px;
    color: #000;

}

.subscribeBtn{
    background-image: linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
    width: fit-content;
    height: fit-content;
    padding: 12px 24px 12px 24px;
    border-radius: 5px;
    position: absolute;
    margin-right: 20px;
}

.subscribeBtnText{
    color:#FFFFFF;
    padding-left: 10px;
    font-family: "Inter",sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.footerBgImg{
    width: 80%;
}

.forLineBreak{
    line-break: anywhere;
}

.textDiv{
    width: 100%;
    display: flex;
    background-color: #000000;
    position: absolute;
    top: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.footerContent{
    display: flex;
    justify-content: center;
    background-color: #000000;
    gap: 70px;
    /* padding-top: 100px ; */
    width: 90%;
}

.footerContentElement{
    margin-top: 60px;
    margin-bottom: 75px;
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.titleText{
    color: #FFFFFF;
    font-family: 'Inter',sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

}

.subText{
    color: #FFFFFF;
    font-family: 'Inter',sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
}
.subText2{
    color: #FFFFFF;
    font-family: 'Inter',sans-serif;
    font-size: 24px;
    font-weight: 400;
}

.linkButton{
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: center ;
    gap: 16px;
}
.linkButton2{
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: start ;
    gap: 16px;
}

.socialIconsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px
}

.footerIcons {
    width: 100%;
    height: auto;
}

.copyRightDiv{
    display: flex;
    justify-content: center;
} 

.contentLogo {
    height: auto;
    object-fit: contain;
    width: 25px;
    justify-self: start;
}
.contentLogo2 {
    height: auto;
    object-fit: contain;
    width: 25px;
    justify-self: start;
    padding-top: 8px;
}
.outerFooterContentElement{
    display: none;
}
.copyRightDiv{
    margin-bottom: 44px;
    width: 90%;
}
@media only screen and (max-width: 1400px) {
    .footerContentElement:nth-child(1){
       display: none;
    }
    .footerContentElement:nth-child(2){
        width: 25%;
     }
    .footerContent{
        font-size: 24px;
        justify-content: space-between;
    }
    .footerContentElement{
        width: 33.33%;
        margin-top: 0px;
        margin-bottom: 60px;
    }
    .titleText{
        font-size: 24px;
    }

    .outerFooterContentElement{
        padding: 45px 110px;
        text-align: center;
        display: flex;
        gap: 32px;
        flex-direction: column;
    }
    .outerFooterContentElement img{
        width: 227px;
        height: 58px;
        align-self: center;
    }
    .outerFooterContentElement .subText{
        text-align: center;
    }
    .subText{
        text-align: center;
        font-size: 20px;
        text-align: left;
    }
    .copyRightDiv{
        margin-bottom: 35px;
    }
}
@media only screen and (max-width: 991px){
    .footerContent{
        flex-direction: column;
        gap: 40px;
        margin-bottom: 50px;
    }
    .outerFooterContentElement{
        padding: 32px 24px;
    }
    .footerContentElement{
        width: 100%;
        margin-bottom: 0;
    }
    .subText,.subText2{
        font-size: 18px;
    }
    .footerContentElement:nth-child(2){
        width: 100%;
     }
     .copyRightDiv{
        margin-bottom: 32px;
    }
}