.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-scroll {
  overflow: hidden;
}
.no-scroll-header-overlay {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: grayscale(0.5) opacity(0.8);
  touch-action: none;
  pointer-events: none;
}
.no-scroll-header-overlay img,
.no-scroll-header-overlay button {
  filter: brightness(-0.5);
}

.slick-track {
  display: flex !important;
  gap: 20px;
}
.regular.slider {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
@media only screen and (max-width: 1400px) {
  .regular.slider {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 768px) {
  .regular.slider {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 576px) {
  .regular.slider {
    grid-template-columns: repeat(1, 1fr);
  }
}

/*Pagination Component starts*/
.pageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 24px;
}
.pageContainer .pageDetails {
  color: #2c2c2c;
  font-size: 16px;
}
.pagination-container {
  align-items: left;
  display: flex;
  flex-direction: flex-row;
  justify-content: center;
  list-style: none;
  gap: 25px;
  padding: 0;
  margin: 0;
}

.pagination-page {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
  color: #4b6cb7;
}

.pagination-active > a {
  font-weight: 400;
  background-color: #4b6cb7;
  padding: 1px 8px;
  color: #ffffff;
  border-radius: 4px;
}

.pagination-next-prev {
  display: flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
}

.pagination-break {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
}

.pagination-next-prev a {
  font-size: 0px;
  line-height: 0; /* Collapse the original line */
  color: #4b6cb7;
}

.pagination-next-prev a[aria-label="Previous page"]::after {
  content: "<";
  font-size: 16px;
  line-height: 22px;
}
.pagination-next-prev a[aria-label="Next page"]::after {
  content: ">";
  font-size: 16px;
  line-height: 22px;
}

/*Pagination Component ends*/

.__rdt_custom_sort_icon__ {
  margin-left: 10px !important;
}
.eGxbrW span.__rdt_custom_sort_icon__ svg {
  opacity: 1 !important;
}
.rdt_Table{
  min-height: 210px!important;
}

/*Date-Picker starts*/
.react-date-picker__inputGroup {
  padding-left: 16px !important;
  color: #959595 !important;
  outline: none;
}
input.react-date-picker__inputGroup__input,
.react-date-picker__inputGroup__leadingZero {
  outline: none;
  font-size: 16px !important;
}
.react-date-picker__wrapper {
  padding-right: 6px;
  border-color: #e0e0e0 !important;
  border-radius: 5px;
}
.react-date-picker svg {
  stroke: #e0e0e0;
}
.react-date-picker__calendar .react-calendar {
  border-radius: 5px;
  border-color: #e0e0e0;
}
.react-calendar * {
  color: #959595 !important;
}
.react-calendar__tile--active,
.react-calendar__tile--active abbr {
  background-color: #4b6cb7 !important;
  color: #ffffff !important;
  background: #4b6cb7 !important;
}
.react-calendar__tile--now,
.react-calendar__tile--now abbr {
  background: #f3f7ff !important;
}
.react-calendar__navigation__label__labelText {
  font-size: 20px;
}
.react-date-picker__inputGroup__input:invalid {
  background: #ffffff !important;
}
/*Date-Picker ends*/

.current::after{
  background-color: #FFFFFF;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.text-red-500{
  color: rgb(239 68 68);
}
.hide{
  display: none;
}