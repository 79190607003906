.sectionContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 100px;
}

/*--------------Section 1 CSS ------------------*/

.section1{
    background-image: url('../images/InquiryBG.png');
    width: 100%;
    padding: 5% 0px;
    display: flex;
    justify-content: center;
    /* flex-direction: column;*/
    background-repeat: no-repeat; 
}

.submitBtn{
    font-family: 'Inter',sans-serif;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 19px;
    background: linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
    height: 35px;
    border-radius: 5px;
    padding: 12px 24px;
}

.section1 .titleText{
    color: #FFFFFF;
    font-family: 'Bebas Neue',sans-serif;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    padding: 20px;
    width: 90%;
    /* padding-left: 100px; */
}

/*--------------Section 2 CSS ------------------*/

.section2{
    width:90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;

}

.imgDiv{
    display: flex;
    align-items:center;
    justify-content: center;
    width: 30%;
}

.contentLogo{
    height: auto;
    width: auto;
    max-width: 90%;
    max-height: 100%;
}


.formDiv{
    width: 100%;
    /* width: 60%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.titleText{
    font-family: 'Inter',sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    align-self: center;
}
.form{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
}
.inputData{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formLabel{
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    gap: 2px;
}
.forminput{
    border: 1.5px solid #E0E0E0;
    width: 98%;
    /* width: 100%; */
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 10px;
}

.formSelectinput{
    border: 1.5px solid #E0E0E0;
    width: calc(100% + 6px);
    /* width: calc(100% + 24px); */
    height: 50px;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 10px;
}

.formMandatoryLogo{
    color: #D02F44;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.subscribeDiv{
    display: flex;
    align-items: center;
}
.subscribeDiv .text{
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 400;

}
.checkbox {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border-width: 2px;
    border-color: #F3AC01;
  }
  
  .btnDiv {
    display: flex;
    justify-content: center;
    height: 50px;
}
.submitBtn{
    background-image: linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
    width: fit-content;
    height: fit-content;
    padding: 12px 24px 12px 24px;
    border-radius: 5px;
    position: absolute;
    margin-right: 20px ;
    margin-bottom: 20px ;
}

.submitBtnText{
    color:#FFFFFF;
    padding-left: 10px;
    font-family: "Inter",sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.selectedClass{
    background-color: #FFF;
    height: 50px;
    width: calc(100% + 24px);
}


@media only screen and (max-width: 1600px){
    .section1 .titleText{
        font-size: 64px;
    }
   
}
@media only screen and (max-width: 1200px){
   .section2 .imgDiv{
    display: none;
   }
   .section2{
    width: calc(100% - 48px);
   }
   .formDiv{
    width: 100%;
   }
   .forminput,.formSelectinput{
    width: unset;
   }
}
@media only screen and (max-width: 768px){
    .section1 .titleText{
        font-size: 36px;
    }
    .section2{
        width: calc(100% - 32px);
    }
    .titleText{
        font-size: 24px;
    }
    .form .formLabel{
        font-size: 16px;
    }
    .subscribeDiv .text{
        font-size: 14px;
    }
}

.error {
    color: red;
    font-size:14px;
    margin-top: -10px;
    font-weight: 500;
}

