.sectionContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 100px;
    margin-bottom: 50px;
}

/*--------------Section 1 CSS ------------------*/

.section1{
    background-image: url('../images/ContactUsBG.png');
    width: 100%;
    padding: 5% 0px;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    background-repeat: round;
}
.section1 .titleText{
    color: #FFFFFF;
    font-family: 'Bebas Neue',sans-serif;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    padding: 20px;
    width: 90%;
}

/*--------------Section 2 CSS ------------------*/

.section2{
    width:90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0px 10px 50px 0px #00000040;
    border-radius: 10px;

}

.infoDiv{
    background-color: #FFF7EF;
    height: auto;
    border-radius: 10px 0 0 10px;
    width: 705px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 70px;
}

.infoElement{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.section2 .titleText{
    color: #F3AC01;
    font-family: "Inter",sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
}

.contentDiv{
    display: flex;
    flex-direction: row;
    gap: 32px;
   
}

.contentLogo {
    height: auto;
    object-fit: contain;
    width:30px;
    align-self: flex-start;
}

.contentText {
    color: #000000;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    text-align: left;
}    
.formDiv{
    width: 65%;
    padding: 35px 60px;
    /* padding: 100px 60px; */
}

.socialIconsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px
}

.linkButton{
    display: flex;
    flex-direction: row;
    text-decoration: none;
    align-items: start ;
    gap: 5px;
}

.socialIcons {
    width: 100%;
    height: auto;
}

.formRow{
    display: flex;
    gap: 64px;
 }

.inputData{
    width: 100%;
    display: flex;
    flex-direction: column;
}
    
.forminput{
   font-size: 24px;
   border-width: 0 0 1.5px;
   border-color: #A1A1A1;
   line-height: 29.5px;
   outline: 0;
}

.form{
    display: flex;
    flex-direction: column;
    gap: 65px;
}
   
.formLabel{
    font-family: "Inter",sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    color: #676767;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

label:focus-within{ 
    color: #1C1C1C;
 } 

.btnDiv {
    display: flex;
    justify-content: right;
}
.contactUsBtn{
    background-image: linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
    width: fit-content;
    height: fit-content;
    padding: 12px 24px 12px 24px;
    border-radius: 5px;
    position: absolute;
    /* margin-right: 20px; */
}

.contactusBtnText{
    color:#FFFFFF;
    padding-left: 10px;
    font-family: "Inter",sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

@media only screen and (max-width: 1600px){
    .section1 .titleText{
        font-size: 64px;
    }
}
@media only screen and (max-width: 1400px){
    .formLabel .forminput{
        font-size: 20px;
    }
}

@media only screen and (max-width: 1200px){
    .section2{
        flex-direction: column;
        width: calc(100% - 48px);
    }
    /* .section2 div{
        width: unset;
    } */
    .infoDiv,.formDiv{
        width: unset;
    }
    .socialIcons{
        width: 40px;
    }
    .infoDiv{
        padding: 40px;
    }
    .formDiv{
        padding: 50px 40px;
        padding-bottom: 80px;
    }
    
  
}

@media only screen and (max-width: 768px){
    .section1 .titleText{
        font-size: 36px;
    }
    .section2{
        width: 100%;
    }
    .formRow{
        flex-direction: column;
        gap: 40px;
    }
    .infoDiv,.formDiv{
        padding: 32px 16px;
        padding-bottom: 70px;
    }
    .form .btnDiv{
        justify-content: center;
    }
    .sectionContainer{
        gap: 60px;
        margin-bottom: 0;
    }
    .infoElement .titleText{
        font-size: 24px;
    }
    .infoElement .contentText{
        font-size: 16px;
    }
    .infoElement{
        gap: 25px;
    }
    .infoElement .contentLogo{
        width: 20px;
    }
    .infoDiv{
        gap: 40px;
        padding-bottom: 40px;
    }
    .formLabel{
        font-size: 16px;
        gap: 16px;
    }
    .socialIcons{
        width: 30px;
    }
}