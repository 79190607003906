.section {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 80px;
}

.reviewDiv {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reviewFormDiv {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reviewDiv .titleText {
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0px;

}

.reviewFormDiv .titleText {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0px;

}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
}

.inputData {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formLabel {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    gap: 2px;
}

.forminput {
    border: 1.5px solid #E0E0E0;
    width: 95%;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 10px;
    background-color: #FFFFFF;

}

.formtextarea {
    border: 1.5px solid #E0E0E0;
    width: 95%;
    height: 140px;
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 10px;
    background-color: #FFFFFF;
}

.btnDiv {
    display: flex;
}

.submitBtn {
    background-image: linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
    width: fit-content;
    height: fit-content;
    padding: 12px 24px 12px 24px;
    border-radius: 5px;
    /* position: absolute; */
}

.submitDisableBtn {
    background-color: #DADADA;
    width: fit-content;
    height: fit-content;
    padding: 12px 24px 12px 24px;
    border-radius: 5px;
}

.submitDisableBtnText {
    color: #676767;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.submitBtnText {
    color: #FFFFFF;
    padding-left: 10px;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}













/* -----------Review Card CSS-------------------- */

.reviewCard {
    display: flex;
    gap: 20PX;
}

.reviewCard .infoDiv {
    display: flex;
    flex-direction: column;
    gap: 20PX;
}

.name {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;

}

.ratingDiv {
    display: flex;
    align-items: center;
    gap: 24px;
}

.reviewDate {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;

}

.reviewTitle {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;

}

.reviewSummary {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.hLine {
    width: 100%;
    height: 2px;
    background-color: #DADADA;
    ;
}

.ratingIcon {
    height: fit-content;
}

.ratingIconAll {
    height: 25px;
    width: auto;
}

@media only screen and (max-width: 768px){
    .section{
        flex-direction: column;
        gap: 40px;
    }
    .reviewDiv,.reviewFormDiv{
        width: 100%;
    }
    .reviewDiv .titleText{
        font-size: 24px;
    }
    .reviewFormDiv .titleText{
        font-size: 20px;
    }
    .submitDisableBtn{
        font-size: 16px;
    }
    .submitBtn .submitBtnText{
        font-size: 16px;
    }
}