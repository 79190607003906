.adminLayout{
    display: flex;
    flex-direction: row;
    padding: 30px 32px;
    background-color: #F5F5F5;
    gap: 20px;
}
.adminChild{
    width: calc(100% - 294px);
    height: calc(100vh - 60px);
    overflow: auto;
}

.adminChild {
    border-radius: 10px;
    
  }
  .adminChild::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
   }
 
   .adminChild::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,.1);
    border-radius: 3px;
 
   }