.greyBgMain{
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 500;
}

.mainDiv{
    /* margin: 50px; */
    display: flex;
    width: 90%;
    border-radius: 50px;
    height: 85.5%;
    background: #FFF7EF;
    z-index: 501;

}

.subDivOne{
    display: flex;
    flex-direction: column;
    width: 40%;
    background: #FFF7EF;
    border-radius: 50px;
    justify-content: space-evenly;
    text-align: center;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    align-items: center;

}

.subDivOneText{
    font-family: 'Bebas Neue',sans-serif;
    padding: 20px;
    font-size: clamp(35px,55px,64px);
    font-weight: 400;
    text-transform: uppercase;
    /* line-height: 85px; */
}

.newCrossFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 81%;
    margin-top: 25px;
}

.crossImg{
    width: 30px;
    height: 30px;
}

.RegisterImg{
    height: auto;
    width: auto;
    max-width: 90%;
    max-height: 90%;
}

.subDivTwo{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 60%;
    border-radius: 50px;
    background: #FFFFFF;
    box-shadow: -10px 0px 20px 0px #00000014;
    padding-bottom: 0px;
    gap: 30px;
    overflow-x: scroll;
}
.subDivTwo2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    border-radius: 50px;
    background: #FFFFFF;
    box-shadow: -10px 0px 20px 0px #00000014;
    padding-bottom: 0px;
    gap: 30px;
    overflow: auto;
}



.darkBlackText{
    font-family: 'Inter',sans-serif;
    font-size: 32px;
    font-weight: 600;
}

.flexHead{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 81%;
}
.flexHead2{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 81%;
    margin-top: 100px;
}

.greyText{
    color: #676767;
    font-family: 'Inter',sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.nameInput{
    width: 100%;
    border: 1.5px solid #E0E0E0;
    color: linear-gradient(0deg, #E0E0E0, #E0E0E0),linear-gradient(0deg, #FFFFFF, #FFFFFF);
    height: 50px;
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 400;
    border-radius: 5px;
    padding-left: 14px;
}

.passwordDiv{
    display: flex;
    position: relative;
    width: calc(100% + 18px);
    align-items: center;
    justify-content: right;
}

.eyeIcon{
    position: absolute;
    padding-right: 10px;

}

.registerStyle{
    border: 1.5px solid #E0E0E0;
    height: 50px;
    border-radius: 5px;
    padding-left: 14px;
    width: calc(100% + 20px);
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 400;

}

.tagNames{
    font-family: 'Inter',sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.inputColumns{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 81%;

}

.setInGap{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.checkboxText{
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.bottomText{
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 400;
    align-self: center;
}

.signUpBtn{
    border-radius: 5px;
    color: #FFFFFF;
    font-family: 'Inter',sans-serif;
    font-size: 18px;
    font-weight: 600;
    /* height: 46px; */
    width: calc(100% + 20px);
    padding: 12px 24px;
    background:linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
}

.hrefSignIn{
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #F3AC01;
}

.checkFlex{
    display: flex;
    gap: 8px;
    align-items: center;
}

.checkHeightset{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px;
}
    

.bottomDivFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap:20px;
    width: 81%;
    margin-bottom: 25px;
}

/* login css */
.RememberFlex{
    display: flex;
    justify-content: space-between;
}

.forgotColor{
    color: #F3AC01;
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    font-weight: 600;


}
/* login css end */

/* -----------------------Sucess msg----------------------------- */
.successMsgDiv{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 134px;
}
.successMsg{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #EDFBD8;
    padding: 15px;
    border: 1.5px solid #84D65A;
    border-radius: 11.40px;
    z-index: 10000;
}

.msgText{
    color: #2B641E;
    /* font-family: 'Roboto',sans-serif; */
    font-size: 20px;
    font-weight: 500;
    
}

@media screen and (min-width:991px) and (max-width:1200px){
    .darkBlackText{
        font-size: 24px;
    }
    .greyText{
        font-size: 16px;
    }
    .subDivOneText{
        font-size: 48px;
    }
    .subDivOne{
        justify-content: space-around;
    }
    .newCrossFlex{
        margin-top: 65px;
    }
    .flexHead2{
        margin-top: 0;
    }
}   

@media screen and (max-width:768px){
    
    .subDivOne{
        display: none;
    }
    .subDivTwo,.subDivTwo2{
        width: 100%;
        border-radius: 30px;
    }
    .mainDiv{
        width: calc(100% - 40px);
        height: calc(100% - 40px);
    }
    .newCrossFlex, .inputColumns,.bottomDivFlex{
        width:calc(100% - 32px);
    }
    .newCrossFlex{
        flex-direction: column;
    }
    .newCrossFlex .flexHead, .flexHead2{
        order: 2;
        align-items: center;
        margin-top: 0;
        width: 100%;
    }
    .crossDiv{
        display: flex;
        justify-content: end;
        width: 100%;
        order: 1;
    }
    .crossDiv .crossImg{
        width: 20px;
        height: 20px;
    }
    .darkBlackText{
        font-size: 20px;
    }
    .greyText{
        font-size: 14px;
        text-align: center;
    }
    .tagNames{
        font-size: 16px;
    }
   .nameInput{
    width:-webkit-fill-available;
    height: 46px;
   }
   .passwordDiv,.registerStyle,.signUpBtn{
    width: -webkit-fill-available;
   }
}   