.sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 100px;
}

/*--------------Section 1 CSS ------------------*/

.section1 {
    background-image: url('../images/TimingsBG.png');
    width: 100%;
    padding: 5% 0px;
    display: flex;
    justify-content: center;
    /* flex-direction: column;*/
    background-repeat: no-repeat;
}


.section1 .titleText {
    color: #FFFFFF;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    padding: 20px;
    width: 90%;
}

/*--------------Section 2 CSS ------------------*/
.Section2 {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.searchAndEntities {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.entites {
    width: 190px;
    height: 50px;
    border: 1.5px solid #E0E0E0;
    border-radius: 5px;
    padding:5px;
}

.searchDiv {
    position: relative;
}

.searchDiv img {
    position: absolute;
    top: 11px;
    right: 24px;
}


.searchInput {
    border-radius: 50px;
    padding: 0px 24px;
    border: 2px solid #F3AC01;
    border-radius: 10px;
    background-color: #FFF7EF;
    width: 340px;
    height: 50px;
    padding-right: 55px;
}

.weekEndText{
    color: #E2200E;
    font-size: 24px;
    font-weight: 500;
    font-style: italic;
}

.paginationDiv{
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 40px;
}

.hidden {
    display: none;
}