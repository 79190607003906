.MaindivGreyBG{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 500;
}

.subMainDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 45%;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
    margin: 50px;
}

.crossIconFlex{
    display:flex;
    justify-content: end;
    width: 97%;
}

.loginBtn{
    font-family: 'Inter',sans-serif;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    background: linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
    height: 35px;
    border-radius: 5px;
    padding: 8px 16px;
    margin-bottom: 22px;
}

.subFlex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 90%;
}

.loginStyle{
    color: #F3AC01;
    font-family: 'Inter',sans-serif;
    font-size: 24px;
    font-weight: 700;

}

.contentDiv{
    font-family: 'Inter',sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 40px;
    color: linear-gradient(0deg, #1C1C1C, #1C1C1C);
    text-align: center;
}


@media only screen and (max-width: 991px){
    .subMainDiv{
        width: calc(100% - 48px);
    }
}