p {
    padding: 0;
    margin: 0;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Section 1 CSS */

.section1 {
    width: 90%;
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.textDiv {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
}

.imgDiv {
    width: 50%;
    display: flex;
    flex-direction: row-reverse;
}

.section1TitleText {
    color: #F3AC01;
    font-family: "Bebas Neue", sans-serif;
    font-size: 64px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    align-self: flex-start;
}

.section1SubText {
    font-family: "Inter", sans-serif;
    font-size: clamp(2rem, 2.5vw, 4rem);
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 0em;
    text-align: left;
}

.homeBgImg {
    height: auto;
    width: 700px;
    max-width: 90%;
    max-height: 100%;
    /* width: 700px;
    max-width: 1100px;
    max-height: 790px; */
}

.registerBtn {
    background-image: linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
    width: fit-content;
    height: fit-content;
    padding: 12px 24px 12px 24px;
    border-radius: 5px;
    align-self: flex-start;
}

.addBottomMargin {
    margin-bottom: 20px;
}

.registerBtnText {
    color: #FFFFFF;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

@keyframes slideh {
    0% {
        right: 0%;
    }

    25% {
        right: 100%;
    }

    50% {
        right: 200%;
    }

    75% {
        right: 300%;
    }

    100% {
        right: 0%;
    }
}

.hmove {
    display: flex;
    position: relative;
    animation: slideh 15s infinite;
}

.hslide {
    width: 100%;
    flex-shrink: 0;
}

.hwrap {
    overflow: hidden;
}

/* .hmove:hover { animation-play-state: paused; } */



/* Section 2 CSS */

.section2 {
    width: 100%;
    background-image: linear-gradient(180deg, #FFF7EF 0%, rgba(255, 247, 239, 0) 100%);
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.setMarginSection2 {
    padding: 4%;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.section2TitleDiv {
    display: flex;
    flex-direction: column;
    gap: 15px;

}

.section2TitleText {
    font-family: "Inter", sans-serif;
    color: #F3AC01;
    font-size: clamp(2rem, 2.5vw, 4rem);
    /* font-size: 36px; */
    font-weight: 500;
}

.section2SubText {
    font-family: 'Inter', sans-serif;
    font-size: clamp(30px, 40px, 48px);
    ;
    font-weight: 700;
}

.courseMapDiv {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
}

.learnAndClockFlex {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.courseDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.courseBtn {
    text-decoration: none;
    min-width: max-content;
}

.courseBtnText {
    color: #1C1C1C;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    text-align: start;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    color: #1C1C1C;
    border: 0px;
    border-bottom: 5px solid #F3AC01;
}

.courseBtnText2 {
    color: #1C1C1C;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    text-align: start;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    color: #1C1C1C;
    border: 0px;

    /* border-bottom: 5px solid #F3AC01; */
}

.cardHolder {
    display: flex;
}

.browseBtn {
    background-image: linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%);
    width: fit-content;
    height: fit-content;
    padding: 12px 24px 12px 24px;
    border-radius: 5px;
    align-self: center;
    margin-bottom: 50px;
}


/* Section 3 CSS */

.section3 {
    width: 100%;
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
    background-color: #FFF7EF;
    /* padding: 70px; */
}

.mainsection {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.section3TextDiv {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 20px 0px;
}

.section3ImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    padding: 20px 0px;
}

.instructorImg {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 70%;

}

.section3TitleText {
    font-family: "Inter", sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    line-height: 68px;
}

.section3YellowText {
    color: #F3AC01;
    font-family: "Inter", sans-serif;
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    line-height: 68px;
}

.section3SubText {
    color: #676767;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

}












/* CARD MODULE CSS */

.card {
    background-color: #FFFFFF;
    /* min-width: 300px; */
    /* width: 23%; */
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cardImg {
    height: 250px;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.textContainer {
    height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.courseTitle {
    color: #F3AC01;
    background-color: #FFF7EF;
    font-family: "Inter", sans-serif;
    font-size: clamp(0.8rem, 1vw, 1.5rem);
    font-weight: 500;
    text-align: left;
    border: 1px solid;
    border-radius: 50px;
    padding: 4px 16px;
    width: fit-content;
    margin-left: 20px;
}

.courseDetail {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
    padding-left: 20PX;
}

.cardFooter {
    display: flex;
    gap: 10px;
    margin-left: 20px;
    align-items: center;

}

.clockIcon {
    width: 18px;
    height: 18px;
}

.rightArrowSet {
    width: 25px;
    height: 12px;
    margin-right: 12px;
}

.timeDetail {
    color: #676767;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: left;
}

.footerVL {
    background-color: #BBBBBB;
    width: 2px;
    height: 20px;
}

.learnMoreDiv {
    background-color: #FFEED9;
    display: flex;
    justify-content: right;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.learnMoreText {
    padding: 10px;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}

/* -----------------------Sucess msg----------------------------- */
.successMsgDiv {
    position: absolute;
}

.successMsg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #EDFBD8;
    padding: 15px;
    border: 1.5px solid #84D65A;
    border-radius: 11.40px;

}

.msgText {
    color: #2B641E;
    /* font-family: 'Roboto',sans-serif; */
    font-size: 20px;
    font-weight: 500;

}






.courseMapDiv {
    display: unset;
}

/* responsive */
@media only screen and (max-width: 1600px) {
    .textDiv {
        gap: 40px;
    }

    .section1TitleText {
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 16px;
    }

    .section1SubText {
        font-size: 32px;
        line-height: 45px;
    }
}

@media only screen and (max-width: 1200px) {
    .section1 {
        width: calc(100% - 48px);
    }

    .setMarginSection2 {
        padding: 0 24px;
        padding-top: 80px;
        padding-bottom: 20px;
    }

    .card {
        margin-bottom: 14px;
    }


    /* section3 css*/
    .mainsection {
        width: calc(100% - 48px);
    }

    .cardHolder {
        display: unset;
    }
}

@media only screen and (max-width: 991px) {
    .section1 {
        flex-direction: column;
        margin-top: 32px;
        gap: 32px;
    }

    .textDiv {
        width: 100%;
        gap: 40px;
        justify-content: center;
    }

    .imgDiv {
        width: 100%;
        justify-content: center;
    }

    .section1TitleText {
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -1px;
        text-align: center;
    }

    .section1SubText {
        text-align: center;
    }

    .registerBtn {
        font-size: 18px;
        align-self: center;
    }

    /* section3 css*/
    .mainsection {
        flex-direction: column;
        gap: 32px;
    }

    .section3TextDiv,
    .section3ImgDiv {
        width: 100%;
    }

    .section3TitleText,
    .section3YellowText,
    .section3SubText {
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .textDiv {
        gap: 16px;
    }

    .cardHolder {
        display: flex;
    }

    .section1 {
        width: calc(100% - 32px);
    }

    .section1TitleText {
        font-size: 32px;
        line-height: 38px;
    }

    .section1SubText {
        font-size: 20px;
        line-height: 30px;
    }

    .registerBtn {
        font-size: 16px;
    }

    .section3TitleText,
    .section3YellowText {
        font-size: 24px;
        line-height: 40px;
    }

    .section3SubText {
        font-size: 16px;
        line-height: 30px;
    }

    .courseTitle,
    .cardFooter {
        margin-left: 0;
    }

    .courseDetail {
        padding-left: 0;
    }

    /*section 2 starts*/
    .setMarginSection2 {
        padding: 40px 16px;
    }

    .section2TitleText,
    .courseDetail {
        font-size: 16px;
    }

    .section2SubText {
        font-size: 24px;
    }

    .courseBtnText,
    .courseBtnText2 {
        font-size: 18px;
    }

    .card {
        gap: 24px;
        margin-bottom: 0;
    }

    .card .textContainer:not(.learnAndClockFlex) {
        gap: 24px;
    }

    .courseDetail,
    .cardFooter {
        padding: 0 16px;
    }

    .courseTitle {
        margin: 0 16px;
    }

    /*section 2 ends*/
    /*section 3 starts*/
    .section3 .mainsection {
        width: calc(100% - 32px);
    }

    /*section 3 ends*/
}

@media screen and (min-width:992px) and (max-width:1200px) {
    .section1 {
        position: relative;
        justify-content: end;
        margin-top: 58px;
    }

    .textDiv {
        position: absolute;
        top: 0px;
        left: 0;
        width: 58%;
    }

    .imgDiv {
        margin-top: 80px;
        width: 65%;
    }

    .homeBgImg {
        width: 100%;
        height: auto;
        max-width: 100%;
    }

    .courseMapDiv {
        display: block;
    }

    .cardHolder {
        display: unset;
    }
}

@media only screen and (max-width: 576px) {
    .card .textContainer {
        height: auto;
    }
}