.coursesPage {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 90px;
}
.courseFilterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addCourseBtn {
  display: flex;
  gap: 8px;
  background-color: #4b6cb7;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 12px 24px;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
}
.courseContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}
.courseCard {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.courseCard .courseCardDetails {
  padding: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.imgContainer {
  position: relative;
  padding-bottom: 40%;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.imgDiv {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.imgDiv img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.courseTitle {
  font-size: 16px;
  line-height: 24px;
  color: #232323;
}

.instructorDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-left: 2px solid #4b6cb7;
  padding-left: 8px;
}
.instructorDetails span {
  font-size: 14px;
  line-height: 17px;
  color: #676767;
}
.instructorName {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.searchInput {
  border-radius: 50px;
  padding: 0px 24px;
  border-color: transparent;
  background-color: #f5f5f5;
  width: 340px;
  height: 50px;
  padding-right: 55px;
}
.courseCardAction {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background-color: #f3f7ff;
}
.courseCardAction .courseDelete {
  color: #d02f44;
}
.courseCardAction span {
  cursor: pointer;
}
.courseActionSeperator {
  width: 2px;
  height: 15px;
  background-color: #959595;
}

/*------------------------------------------------ Add Courses Starts ------------------------------------------*/

.addCoursePage {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 90px;
}
.headerTitle {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}
.basicDetailsForm,
.primaryModuleForm,
.secondaryFields,
.tertiaryModuleForm {
  display: flex;
  flex-direction: column;
}
.primaryModuleForm,
.secondaryFields,
.tertiaryModuleForm {
  gap: 24px;
}

.threeInputFields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}
.formFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.primaryModuleForm .formFields {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}
.fieldDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.fieldDiv label {
  font-size: 18px;
  line-height: 22px;
}
.fieldDiv input,
.fieldDiv textarea,
.fieldDiv input[type="file"],
.fieldDiv select {
  height: 48px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 16px;
  padding: 0 16px;
  color: #959595;
  position: relative;
}
.fieldDiv textarea {
  height: auto;
  padding: 16px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  resize: none;
}
.fieldDiv .imageUploadContainer {
  height: 139px;
  border: 1px dashed #e0e0e0;
  border-radius: 5px;
  font-size: 16px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.radioDivContainer {
  display: flex;
  gap: 100px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.radioDivContainer .radioDiv {
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.radioOptions {
  display: flex;
  gap: 30px;
}
.radioOptions label {
  display: flex;
  gap: 8px;
}
.radioOptions input {
  margin: 0;
  height: 24px;
  width: 24px;
}
.nextButtonDiv {
  margin-top: 60px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.nextButton {
  font-size: 18px;
  background-color: #4b6cb7;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 5px;
}
.fieldDiv input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.fieldDiv input[type="file"] + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

.fieldDiv input[type="file"]:focus + label,
.fieldDiv input[type="file"] + label:hover {
  background-color: red;
}
.imgTypeFormat {
  font-size: 16px;
  line-height: 20px;
  color: #979797;
}
.imgUploadMessage {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}
.imgUploadMessage span {
  color: #4b6cb7;
}

.addModuleButtonDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 32px;
  gap: 20px;
}
.addModuleButton {
  border: 1px solid #4b6cb7;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border-radius: 5px;
  color: #4b6cb7;
}
.secondaryFields .title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.tertiaryModuleForm .title {
  display: flex;
  align-items: center;
}
.secondaryFields .addModuleButtonDiv {
  margin-top: 8px;
}
.titleDivider {
  width: 2px;
  height: 18px;
  background-color: #979797;
  margin: 0 24px;
}
.headerDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backDiv {
  display: flex;
  gap: 8px;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.backDiv img {
  height: 12px;
  width: 12px;
}
.previewImage {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

/*------------------------------------------------ Add Courses Ends ------------------------------------------*/
